import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { useTranslation } from "../lang/TranslationProvider";
import { useSelector } from "react-redux";
import moment from "moment";
import parse from 'html-react-parser';

const Blog = () => {

	const { translate } = useTranslation();
	const { itemId } = useParams();
	const [blogData, setBlogData] = useState(null);
	const [blogs, setBlogs] = useState([]);
	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		getBlogs(1);
		getBlogById();
	}, [itemId])

	const getBlogById = () => {
		if (!itemId) {
			return false;
		}
		setBlogData(null);
		MainService.callApi({
			api: ApiService.getBlogById,
			data: itemId,
			cb: (response) => {
				if (response && response.data) {
					setBlogData(response.data);
				}
			},
		});
	};

	const getBlogs = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
		}
		setBlogs([]);
		MainService.callApi({
			api: ApiService.getBlogs,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagedData && data.pagedData.length) {
						setBlogs(data.pagedData);
					} else {
						setBlogs([]);
					}
				}
			},
		});
	}

	return (

		<section className="cont">
			<Link className="back-btn" style={{ marginBottom: "40px" }} to="#" onClick={() => window.history.back()}>{translate("TR_BACK")}</Link>
			{
				blogData ?
					<React.Fragment>
						<pre className="debug_dump">--- {moment(blogData.date).format("YYYY-MM-DD")}</pre>
						<div className="main-page-bunner exhibitions-page-bunner blog-page-bunner">
							<div className="exhibition-item blog-item pull-left">
								<div className="date">
									<b>{moment(blogData.date).format("YYYY-MM-DD")}</b>
									<div className="clear"></div>
								</div>
								<div className="image">
									<div className="image-cont">
										{
											blogData.imagePaths && blogData.imagePaths.length ?
												blogData.imagePaths.map((item, index) => {
													if (item.isMain) {
														return <img key={index} src={item.filePath} alt="image" style={{ height: "100%" }} />
													}
												})
												: <img src="images/our-images/no-image.png" style={{ height: "100%" }} alt="image" />
										}
									</div>
								</div>
							</div>
							<div className="text pull-right">
								<span className="label">{translate("TR_BLOG")}</span>
								<div className="line"></div>
								<h1 className="name">{MainService.getValueByLanguageId(blogData.names, languageId)}</h1>
								<div className="text-block">
									<p>{MainService.getValueByLanguageId(blogData.shortDescriptions, languageId)}</p>
								</div>
								<div className="clear"></div>
							</div>
							<div className="clear"></div>
							<div className="bottom-text">
								{parse(MainService.getValueByLanguageId(blogData.descriptions, languageId))}
							</div>
						</div>
					</React.Fragment>
					: null
			}

			<div className="exhibitions-list exhibitions-list-margin blogs-list-margin blogs-list-margin-no-shadow">
				<div className="title title-small mobile">{translate("TR_OTHER_BLOG_ENTRIES")}</div>
				<div className="exhibition-item-cont mobile">
					<div className="exhibition-item-cont-block">
						{
							blogs && blogs.length && blogs.filter(item => item.id !== +itemId).length ?
								blogs.filter(item => item.id !== +itemId).map((item, index) => {
									return <div key={index} className="exhibition-item blog-item">
										<div className="date">
											<b style={{ textWrap: "nowrap" }}>{moment(item.date).format("YYYY-MM-DD")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link
												className="image-cont"
												to={`/blog/${item.id}`}
											>
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} alt="image" />
											</Link>
										</div>
										<Link
											className="name"
											to={`/blog/${item.id}`}
										>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								})
								: null
						}


						<div className="clear"></div>
					</div>
				</div>
				<div className="column pull-left desktop">
					<div className="title title-small">{translate("TR_OTHER_BLOG_ENTRIES")}</div>
					{
						blogs && blogs.length && blogs.filter(item => item.id !== +itemId).length ?
							blogs.filter(item => item.id !== +itemId).map((item, index) => {
								if (index % 2 === 0) {
									return <div key={index} className="exhibition-item blog-item">
										<div className="date">
											<b style={{ textWrap: "nowrap" }}>{moment(item.date).format("YYYY-MM-DD")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link
												className="image-cont"
												to={`/blog/${item.id}`}
											>
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} alt="image" />
											</Link>
										</div>
										<Link
											className="name"
											to={`/blog/${item.id}`}
										>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								}
							})
							: null
					}

				</div>
				<div className="column pull-right desktop">
					{
						blogs && blogs.length && blogs.filter(item => item.id !== +itemId).length ?
							blogs.filter(item => item.id !== +itemId).map((item, index) => {
								if (index % 2 === 1) {
									return <div key={index} className="exhibition-item blog-item">
										<div className="date">
											<b style={{ textWrap: "nowrap" }}>{moment(item.date).format("YYYY-MM-DD")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link
												className="image-cont"
												to={`/blog/${item.id}`}
											>
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} alt="image" />
											</Link>
										</div>
										<Link
											className="name"
											to={`/blog/${item.id}`}
										>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								}
							})
							: null
					}

				</div>
				<div className="clear"></div>
			</div>


		</section>
	)
}

export default Blog