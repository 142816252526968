// -------------------------------------------------------------------------------------
export const IDENTITY_API_URL_KEY = "https://identity.formandseasons.com";
export const API_URL_KEY = "https://api.formandseasons.com";
// -------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = "https://9d6b-87-241-138-217.ngrok-free.app";
// export const API_URL_KEY = "https://d559-87-241-138-217.ngrok-free.app";
// -------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = "http://localhost:63002";
// export const API_URL_KEY = "http://localhost:31231";

