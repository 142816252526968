import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import { ApiService } from "../helpers/services/apiSrevice";
import MainService from "../helpers/services/mainService";
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useTranslation } from "../lang/TranslationProvider";
import LightboxComponent from "../components/lightbox-component/LightboxComponent";
import { addProductToCart, addProductToWishList, removeProductFromWishList } from "../store/reducers/productReducer";
import { Autoplay } from "swiper/modules";
import useWindowSize from "../components/hooks/useWindowSize";
import { ViewPosition } from "../components/animation/ViewPosition";
import { motion } from "framer-motion";

export default function Home() {

	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { wishListProducts, cartProducts } = useSelector(state => state.product);
	const [artistsSwiperRef, setArtistsSwiperRef] = useState(null);
	const [artistWorksSwiperRef, setArtistWorksSwiperRef] = useState(null);
	const [exhibitionsSwiperRef, setExhibitionsSwiperRef] = useState(null);
	const [artsSwiperRef, setArtsSwiperRef] = useState(null);
	const [ready, setReady] = useState(false);
	const [pageContents, setPageContents] = useState([]);
	const [artists, setArtists] = useState([]);
	const [exhibitions, setExhibitions] = useState([]);
	const [artworks, setArtworks] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [filePath, setFilePath] = useState("");
	const windowSize = useWindowSize();

	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);

	// useEffect(() => {
	// 	if (pageContents && pageContents.length) {
	// 		const videoElement = document.getElementById("main-page-video");
	// 		if (videoElement) {
	// 			videoElement.play().then(() => {
	// 			}).catch((error) => {
	// 				console.error(error);
	// 			});
	// 		}
	// 	}
	// }, [pageContents])

	useEffect(() => {
		if (pageContents && pageContents.length) {
			const videoElement = document.getElementById("main-page-video");
			if (videoElement) {
				const playVideo = () => {
					videoElement.play().catch((error) => {
						console.error(error);
					});
				};

				videoElement.addEventListener('canplaythrough', playVideo);
				return () => {
					videoElement.removeEventListener('canplaythrough', playVideo);
				};
			}
		}
	}, [pageContents]);

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	const handleScroll = () => {
		window.scrollBy(0, 10);
	};

	useEffect(() => {
		getPageContentsByKeyNames();
		getArtists();
		getExhibitions();
		getArtworks();
	}, [])

	const getArtworks = () => {
		const data = {
			pageNumber: 1,
			pageSize: 20,
		}
		MainService.callApi({
			api: ApiService.getArtworks,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagedData && data.pagedData.length) {
						setArtworks(data.pagedData);
					} else {
						setArtworks([]);
					}
				}
			},
		});
	}

	const getArtists = () => {
		const data = {
			pageNumber: 1,
			pageSize: 20,
			artworksCount: 10,
		}
		MainService.callApi({
			api: ApiService.getArtists,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagedData && data.pagedData.length) {
						setArtists(data.pagedData);
					} else {
						setArtists([]);
					}
				}
			},
		});
	}

	const getExhibitions = () => {
		const data = {
			pageNumber: 1,
			pageSize: 20,
		}
		MainService.callApi({
			api: ApiService.getExhibitions,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagedData && data.pagedData.length) {
						setExhibitions(data.pagedData);
					} else {
						setExhibitions([]);
					}
				}
			},
		});
	}

	const getPageContentsByKeyNames = () => {
		MainService.callApi({
			api: ApiService.getPageContentsByKeyNames,
			data: ["KEY_VIDEO", "KEY_COVER", "KEY_ARTIST", "KEY_EXHIBITIONS", "KEY_STORE", "KEY_ABOUT_THE_GALLERY"],
			cb: (response) => {
				if (response && response.data) {
					setPageContents(response.data);
				}
				setTimeout(() => {
					handleScroll();
					setReady(true);
				}, 1000);
			},
		});
	};

	const getPageContentByKey = (key) => {
		if (!key || !pageContents || !pageContents.length) {
			return "";
		}
		const isExist = pageContents.find(el => el.keyName === key);
		return isExist || null;
	}

	const getPageContentFilePathByKey = (key) => {
		if (!key || !pageContents || !pageContents.length) {
			return "";
		}
		const isExist = pageContents.find(el => el.keyName === key);
		if (isExist) {
			if (isExist && isExist.imagePaths && isExist.imagePaths.length) {
				if (key === "KEY_COVER") {
					return isExist.imagePaths || [];
				} else {
					return isExist.imagePaths[0] || "";
				}
			} else {
				return "";
			}
		} else {
			return null;
		}
	}

	if (!pageContents || !pageContents.length) {
		return null;
	}

	return (
		<div className="main-page">
			<LightboxComponent
				isOpen={isOpen}
				filePath={filePath}
				onHide={() => {
					setIsOpen(false);
					setFilePath("");
				}}
			/>

			<section className="cont">

				{/* section 1 Video */}
				{
					// getPageContentFilePathByKey("KEY_VIDEO") ?
					// 	<ViewPosition>
					// 		{isVisible => (
					// 			<motion.div
					// 				initial={{ opacity: 0, y: 400 }}
					// 				animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 400 }}
					// 				exit={{ opacity: 0, y: 400 }}
					// 				duration={1}
					// 				ready={ready}
					// 				transition={{ duration: 1 }}
					// 			>
					// 				<div className="video-block wow fadeInUp">
					// 					<div className="video">
					// 						<video autoPlay={true} height="100%" id="main-page-video" loop={true} muted={true} playsInline="" width="100%">
					// 							<source src={getPageContentFilePathByKey("KEY_VIDEO")} type="video/mp4" />
					// 						</video>
					// 					</div>
					// 				</div>
					// 			</motion.div>
					// 		)}
					// 	</ViewPosition>
					// 	:
					getPageContentFilePathByKey("KEY_VIDEO") ? (
						<ViewPosition>
							{isVisible => (
								<motion.div
									initial={{ opacity: 0, y: 400 }}
									animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 400 }}
									exit={{ opacity: 0, y: 400 }}
									transition={{ duration: 1 }}
								>
									<div className="video-block wow fadeInUp">
										<div className="video">
											<video
												autoPlay={true}
												height="100%"
												id="main-page-video"
												loop={true}
												muted={true}
												playsInline
												width="100%"
											>
												<source src={getPageContentFilePathByKey("KEY_VIDEO")} type="video/mp4" />
											</video>
										</div>
									</div>
								</motion.div>
							)}
						</ViewPosition>
					) :
						getPageContentFilePathByKey("KEY_COVER") && getPageContentFilePathByKey("KEY_COVER").length ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, y: 400 }}
										animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 400 }}
										exit={{ opacity: 0, y: 400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>

										<div className="video-block wow fadeInUp">
											<div className="video">
												<Swiper
													className="mySwiper"
													loop={true}
													modules={[Autoplay]}
													autoplay={{
														delay: 5000,
														disableOnInteraction: false,
													}}
												>
													{
														getPageContentFilePathByKey("KEY_COVER").map((item, index) => {
															return (
																<SwiperSlide key={index}>
																	<div className="cover-image" style={{ backgroundImage: `url(${item})` }} />
																</SwiperSlide>
															);
														})
													}
												</Swiper>
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>

							: null
				}


				{/* section 2 (image left) */}
				<div className="main-page-bunner main-page-bunner-main-artist">
					{
						getPageContentFilePathByKey("KEY_ARTIST") ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, x: -400 }}
										animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -400 }}
										exit={{ opacity: 0, x: -400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>
										<div className="image pull-left">
											<div className="image-cont wow fadeInLeft">
												<img src={getPageContentFilePathByKey("KEY_ARTIST")} alt="image" />
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}
					{
						getPageContentByKey("KEY_ARTIST") ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, x: 400 }}
										animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : 400 }}
										exit={{ opacity: 0, x: 400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>
										<div className="text pull-right wow fadeInRight">
											<span className="label">
												{MainService.getValueByLanguageId(getPageContentByKey("KEY_ARTIST")?.titles, languageId)}
											</span>
											<div className="line"></div>
											<h1 className="name">
												{
													parse(MainService.getValueByLanguageId(getPageContentByKey("KEY_ARTIST")?.contents, languageId))
												}
											</h1>
											<div className="desktop">
												<Link className="btn" to="/artists">
													{MainService.getValueByLanguageId(getPageContentByKey("KEY_ARTIST")?.buttons, languageId)}
												</Link>
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}
					<div className="clear"></div>
				</div>



				{/* section 3 Artists Carousel */}
				<div>
					{
						artists && artists.length ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, y: 200 }}
										animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 200 }}
										exit={{ opacity: 0, y: 200 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>
										<div className="artist-list wow fadeInUp">

											<div className="nav desktop">
												<div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artistsSwiperRef?.slidePrev()} />
												<div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artistsSwiperRef?.slideNext()} />
											</div>

											<div className="list artists-carousel">
												<Swiper
													slidesPerView={1}
													spaceBetween={10}
													onSwiper={(ref) => setArtistsSwiperRef(ref)}
													loop
													updateOnWindowResize
													breakpoints={{
														640: {
															slidesPerView: 2,
															spaceBetween: 0,
														},
														768: {
															slidesPerView: 3,
															spaceBetween: 40,
														},
														1024: {
															slidesPerView: 4,
															spaceBetween: 55,
														},
													}}
													className="mySwiper"
												>
													{
														artists.map((item, index) => {
															return <SwiperSlide key={index}>
																<div className="item">
																	<div className="image-block">
																		<Link className="image" to={`/artist/${item.id}`}>
																			<div className="image-cont"
																				style={{ backgroundImage: `url(${item.imagePath ? item.imagePath : "images/our-images/no-image.png"})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}
																			>
																			</div>
																		</Link>
																		<div className="work-list" count="6">

																			{
																				item.artworks && item.artworks.length ?
																					<React.Fragment>
																						<div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slidePrev()}></div>
																						<div className="list-work">
																							<div className="list-cont">
																								{
																									item.artworks && item.artworks.length ?
																										<Swiper
																											slidesPerView={1}
																											spaceBetween={10}
																											loop
																											onSwiper={(ref) => setArtistWorksSwiperRef(ref)}
																											updateOnWindowResize
																											breakpoints={{
																												1024: {
																													slidesPerView: 4,
																													spaceBetween: 0,
																												},
																											}}
																											className="mySwiper"
																										>
																											{
																												item.artworks.map((el, idx) => {
																													return <SwiperSlide key={idx}>
																														<Link className="work-image fancybox"
																															to="#"
																															onClick={() => {
																																setIsOpen(true);
																																setFilePath(el.imagePath)
																															}}
																														>
																															<img src={el.imagePath ? el.imagePath : "images/our-images/no-image.png"} alt="image" />
																														</Link>
																													</SwiperSlide>
																												})
																											}


																										</Swiper>
																										: null
																								}
																							</div>
																						</div>
																						<div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slideNext()}></div>
																					</React.Fragment>
																					: null
																			}

																			<div className="clear"></div>
																		</div>
																	</div>
																	<Link className="name" to={`/artist/${item.id}`}>
																		{MainService.getValueByLanguageId(item.names, languageId)}
																	</Link>
																	<div className="line"></div>
																	<div className="count">{item.artworksCount} {translate("TR_WORK")}</div>
																	<Link className="view" to={`/artist/${item.id}`}>{translate("TR_VIEW")}</Link>
																	<div className="clear"></div>
																</div>
															</SwiperSlide>
														})
													}

												</Swiper>
											</div>
										</div>
										<div className="mobile btn-all-artist">
											<Link className="btn" to="/artists">{translate("TR_ALL")}</Link>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}
				</div>



				{/* section 4 (image right) */}
				<div className="main-page-bunner small">
					<ViewPosition>
						{isVisible => (
							<motion.div
								initial={{ opacity: 0, x: -400 }}
								animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -400 }}
								exit={{ opacity: 0, x: -400 }}
								duration={1}
								ready={ready}
								transition={{ duration: 1 }}
							>
								{
									getPageContentByKey("KEY_EXHIBITIONS") ?
										<div className="text pull-left wow fadeInLeft">
											<span className="label">
												{MainService.getValueByLanguageId(getPageContentByKey("KEY_EXHIBITIONS")?.titles, languageId)}
											</span>
											<div className="line"></div>
											<h2 className="name">
												{
													parse(MainService.getValueByLanguageId(getPageContentByKey("KEY_EXHIBITIONS")?.contents, languageId))
												}
											</h2>
											<div className="desktop">
												<Link className="btn" to="/exhibitions">
													{MainService.getValueByLanguageId(getPageContentByKey("KEY_EXHIBITIONS")?.buttons, languageId)}
												</Link>
											</div>
										</div>
										: null
								}
							</motion.div>
						)}
					</ViewPosition>



					{
						getPageContentFilePathByKey("KEY_EXHIBITIONS") ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, x: 400 }}
										animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : 400 }}
										exit={{ opacity: 0, x: 400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>
										<div className="image pull-right">
											<div className="image-cont wow fadeInRight">
												<img src={getPageContentFilePathByKey("KEY_EXHIBITIONS")} alt="image" />
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}

					<div className="clear"></div>
				</div>



				{/* section 5 Exhibitions */}
				{
					exhibitions && exhibitions.length ?
						<ViewPosition>
							{isVisible => (
								<motion.div
									initial={{ opacity: 0, y: 200 }}
									animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 200 }}
									exit={{ opacity: 0, y: 200 }}
									duration={1}
									ready={ready}
									transition={{ duration: 1 }}
								>
									<div className="news-list wow fadeInUp">
										<div className="nav desktop" style={{ top: "220px" }}>
											<div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => exhibitionsSwiperRef?.slidePrev()} />
											<div className="next d-block" style={{ cursor: "pointer" }} onClick={() => exhibitionsSwiperRef?.slideNext()} />
										</div>
										<div className="list">
											<Swiper
												slidesPerView={1}
												spaceBetween={10}
												onSwiper={(ref) => setExhibitionsSwiperRef(ref)}
												loop
												updateOnWindowResize
												breakpoints={{
													640: {
														slidesPerView: 2,
														spaceBetween: 0,
													},
													768: {
														slidesPerView: 2,
														spaceBetween: 40,
													},
													1024: {
														slidesPerView: 3,
														spaceBetween: 130,
													},
												}}
											>
												{
													exhibitions.map((item, index) => {
														return <SwiperSlide key={index}>
															<div className="item">
																<Link className="image" to={`/exhibition/${item.id}`}>
																	<div className="image-cont">
																		<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} alt="image" />
																	</div>
																</Link>
																<Link className="name" to={`/exhibition/${item.id}`}>
																	{MainService.getValueByLanguageId(item.names, languageId)}</Link>
																<div className="short">
																	{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}
																</div>
																<div className="line"></div>
																<Link className="view" to={`/exhibition/${item.id}`}>{translate("TR_VIEW")}</Link>
															</div>
														</SwiperSlide>
													})
												}
											</Swiper>
										</div>
										<div className="clear"></div>
									</div>
								</motion.div>
							)}
						</ViewPosition>
						: null
				}


				{/* section 6 (image right) */}
				<div className="main-page-bunner">

					{
						getPageContentFilePathByKey("KEY_STORE") ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, x: -400 }}
										animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -400 }}
										exit={{ opacity: 0, x: -400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>
										<div className="image pull-left desktop">
											<div className="image-cont wow fadeInLeft">
												<img src={getPageContentFilePathByKey("KEY_STORE")} alt="image" />
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}

					{
						getPageContentByKey("KEY_STORE") ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, x: 400 }}
										animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : 400 }}
										exit={{ opacity: 0, x: 400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>
										<div className="text pull-right wow fadeInRight">
											<span className="label">{MainService.getValueByLanguageId(getPageContentByKey("KEY_STORE")?.titles, languageId)}</span>
											<div className="line"></div>
											<h2 className="name">
												{
													parse(MainService.getValueByLanguageId(getPageContentByKey("KEY_STORE")?.contents, languageId))
												}
											</h2>
											<div className="desktop">
												<Link className="btn" to="/store">
													{MainService.getValueByLanguageId(getPageContentByKey("KEY_STORE")?.buttons, languageId)}
												</Link>
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}
					{
						getPageContentFilePathByKey("KEY_STORE") ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, x: -400 }}
										animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -400 }}
										exit={{ opacity: 0, x: -400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>
										<div className="image pull-left mobile wow fadeInLeft">
											<div className="image-cont">
												<img src={getPageContentFilePathByKey("KEY_STORE")} alt="image" />
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}


					<div className="clear"></div>
				</div>



				{/* section 6 Products */}
				{
					artworks && artworks.length ?

						<ViewPosition>
							{isVisible => (
								<motion.div
									initial={{ opacity: 0, y: 200 }}
									animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 200 }}
									exit={{ opacity: 0, y: 200 }}
									duration={1}
									ready={ready}
									transition={{ duration: 1 }}
								>
									<div className="product-list product-list-line wow fadeInUp">
										<div className="title">{translate("TR_STORE")}</div>
										<div className="nav desktop">
											<div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artsSwiperRef?.slidePrev()} />
											<div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artsSwiperRef?.slideNext()} />
										</div>
										<div className="list search-page-artworks" style={{ display: "flex", flexWrap: "wrap" }}>
											<Swiper
												slidesPerView={1}
												spaceBetween={10}
												onSwiper={(ref) => setArtsSwiperRef(ref)}
												loop
												updateOnWindowResize
												breakpoints={{
													640: {
														slidesPerView: 2,
														spaceBetween: 0,
													},
													768: {
														slidesPerView: 3,
														spaceBetween: 40,
													},
													1024: {
														slidesPerView: 4,
														spaceBetween: 55,
													},
												}}
												className="mySwiper"
											>
												{
													artworks.map((item, index) => {
														const isInWishList = wishListProducts.some(el => el.id === item.id);
														const isInShoppingCart = cartProducts.some(el => el.id === item.id);
														return <SwiperSlide key={index}>
															<div className="item">
																<div
																	className={`fav to-fav ${isInWishList ? 'remove-fav' : ''}`}
																	onClick={() => {
																		if (isInWishList) {
																			dispatch(removeProductFromWishList(item));
																		} else {
																			dispatch(addProductToWishList(item));
																		}
																	}}
																/>
																<Link className="image" to={`/artwork/${item.id}`}>
																	<div className="image-cont">
																		<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} alt="image" />
																	</div>
																</Link>
																{
																	// item.price || item.oldPrice ?
																	<Link
																		className="cart add-to-cart"
																		to={`${isInShoppingCart ? "/cart" : "#"}`}
																		onClick={() => {
																			if (!isInShoppingCart) {
																				dispatch(addProductToCart(item));
																				alert(translate("TR_ADDED_TO_CART"))
																			}
																		}}
																	>
																	</Link>
																	// : null
																}
																<Link className="name" to={`/artwork/${item.id}`}>{MainService.getValueByLanguageId(item.names, languageId)}</Link>
																<span>{MainService.getValueByLanguageId(item.artistNames, languageId)}</span>
																<div className="line"></div>
																<b className="price">
																	<span></span> &nbsp;
																</b>
																<Link className="view" to={`/artwork/${item.id}`}>{translate("TR_VIEW")}</Link>
																<div className="clear"></div>
															</div>
														</SwiperSlide>
													})
												}
											</Swiper>
										</div>
									</div>
									<div className="mobile btn-online-store">
										<Link className="btn" to="/store">{translate("TR_ONLINE_STORE")}</Link>
									</div>
								</motion.div>
							)}
						</ViewPosition>
						: null
				}



				{/* section 7 (image right) */}
				<div className="about-short" >
					<ViewPosition>
						{isVisible => (
							<motion.div
								initial={{ opacity: 0, x: -400 }}
								animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -400 }}
								exit={{ opacity: 0, x: -400 }}
								duration={1}
								ready={ready}
								transition={{ duration: 1 }}
							>
								<div className="text pull-left" >
									{
										getPageContentByKey("KEY_ABOUT_THE_GALLERY") ?
											<div className="col-big pull-left wow fadeInLeft" style={{ minHeight: "100px" }}>
												<div className="title">
													{MainService.getValueByLanguageId(getPageContentByKey("KEY_ABOUT_THE_GALLERY")?.titles, languageId)}
												</div>
												<div className="line"></div>
												<h3>
													{
														parse(MainService.getValueByLanguageId(getPageContentByKey("KEY_ABOUT_THE_GALLERY")?.contents, languageId))
													}
												</h3>
												<Link className="btn" to="/store">
													{MainService.getValueByLanguageId(getPageContentByKey("KEY_ABOUT_THE_GALLERY")?.buttons, languageId)}
												</Link>
											</div>
											: null
									}
								</div>
							</motion.div>
						)}
					</ViewPosition>
					{
						getPageContentFilePathByKey("KEY_ABOUT_THE_GALLERY") ?
							<ViewPosition>
								{isVisible => (
									<motion.div
										initial={{ opacity: 0, x: 400 }}
										animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : 400 }}
										exit={{ opacity: 0, x: 400 }}
										duration={1}
										ready={ready}
										transition={{ duration: 1 }}
									>

										<div className="image pull-right desktop wow fadeInRight">
											<div className="image-cont">
												<img src={getPageContentFilePathByKey("KEY_ABOUT_THE_GALLERY")} alt="image" />
											</div>
										</div>
									</motion.div>
								)}
							</ViewPosition>
							: null
					}
					<div className="clear"></div>
				</div>




			</section>
		</div>
	);
}
