import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { useTranslation } from "../lang/TranslationProvider";
import { useDispatch, useSelector } from "react-redux";
import { addProductToWishList, removeProductFromWishList } from "../store/reducers/productReducer";
import moment from "moment";
import LightboxComponent from "../components/lightbox-component/LightboxComponent";
import Letter from "../components/letter/Letter";

export default function Artwork() {

	const { translate } = useTranslation();
	const dispatch = useDispatch();
	const { wishListProducts, cartProducts } = useSelector(state => state.product);
	const { itemId } = useParams();
	const [isActive, setIsActive] = useState(false);
	const [isShow, setIsShow] = useState(false);
	const descriptionRef = useRef(null);
	const elementRef = useRef(null);
	const [artworkData, setArtworkData] = useState(null);
	const [isInWishList, setIsInWishList] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [filePath, setFilePath] = useState("");
	const [isShowModal, setIsShowModal] = useState(false);

	const [artistId, setArtistId] = useState(null);
	const [artistWorks, setArtistWorks] = useState([]);
	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState(null);
	const targetRef = useRef(null);

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		if (wishListProducts && wishListProducts.length) {
			const isExist = wishListProducts.find(el => el.id === +itemId);
			if (isExist) {
				setIsInWishList(true);
			} else {
				setIsInWishList(false);
			}
		} else {
			setIsInWishList(false);
		}
	}, [wishListProducts])

	useEffect(() => {
		getArtworkById();
	}, [itemId])

	const getArtworkById = () => {
		if (!itemId) {
			return false;
		}
		setArtworkData(null);
		MainService.callApi({
			api: ApiService.getArtworkById,
			data: itemId,
			cb: (response) => {
				if (response && response.data) {
					let data = { ...response.data };
					if (data.imagePaths && data.imagePaths.length) {
						data.imagePaths = data.imagePaths.sort((a, b) => b.isMain - a.isMain)
					}
					setArtworkData(data);
					if (data.artistId) {
						setArtistId(data.artistId)
					}
				}
			},
		});
	};

	useEffect(() => {
		getAtristWorksById(1)
	}, [artistId])

	const getAtristWorksById = (currentPage) => {
		if (!artistId) { return false; }
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
			artistId,
		}
		setIsLoading(true);
		MainService.callApi({
			api: ApiService.getAtristWorksById,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagination) {
						setPagination(data.pagination)
					}
					if (data.pagedData && data.pagedData.length) {
						if (currentPage > 1) {
							setArtistWorks([...artistWorks, ...data.pagedData]);
						} else {
							setArtistWorks(data.pagedData);
						}
					} else {
						setArtistWorks([]);
					}
				}
				setIsLoading(false);
				setCurrentPage(currentPage)
			},
			_finally: () => {
				setIsLoading(false);
			},
		});
	}

	useEffect(() => {
		const handleScroll = () => {
			if (descriptionRef.current?.getBoundingClientRect()?.top <= 100) {
				setIsActive(true);
			}
			if (window.scrollY < 300) {
				setIsActive(false);
			}
			if (elementRef.current?.getBoundingClientRect()?.top <= 0) {
				setIsShow(true);
			} else {
				setIsShow(false);
			}

		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [descriptionRef, elementRef, isActive]);


	return (
		<div className="cont">
			<LightboxComponent
				isOpen={isOpen}
				filePath={filePath}
				onHide={() => {
					setIsOpen(false);
					setFilePath("");
				}}
			/>
			<Letter isShowModal={isShowModal} setIsShowModal={setIsShowModal} />
			<Link className="back-btn" to="#" onClick={() => window.history.back()}>{translate("TR_BACK")}</Link>
			{
				artworkData ?
					<div className="product">
						<div className="product-info pull-left">
							<div

								className="product-info-cont"
								style={{
									position: isActive ? "fixed" : "absolute",
									bottom: isActive ? 0 : "inherit",
									opacity: isShow ? 0 : 1
								}}
							>
								<div className="navs">
									{
										!isInWishList ?
											<Link class="fav to-fav" to="#"
												onClick={() => {
													dispatch(addProductToWishList(artworkData));
												}}
											>
												{translate("TR_ADD_TO_WISH")}
											</Link>
											: <Link class="fav to-fav remove-fav" to="#"
												onClick={() => {
													dispatch(removeProductFromWishList(artworkData));
												}}
											>
												{translate("TR_REMOVE")}
											</Link>
									}
									<div className="clear"></div>
								</div>
								<div className="line"></div>
								<h1>{MainService.getValueByLanguageId(artworkData.names, languageId)}</h1>
								<div className="params">
									<span>
										<p style={{ display: "flex" }}>
											{
												artworkData.materials && artworkData.materials.length ?
													artworkData.materials.map((el, idx) => {
														return <span key={idx}>{translate(el)}{`${artworkData.materials.length === idx + 1 ? "" : ","}`}</span>
													})
													: null
											}
										</p>
										<p>{artworkData.size} {translate("TR_SM")}, {moment(artworkData.productionDate).format("YYYY")} {translate("TR_YEAR")}</p>
									</span>
								</div>
								<div className="price-block">
									<div className="price">{translate("TR_PRICE_ON_REQUEST")}</div>
									<Link
										className="btn btn-no-str btn-view btn-write btn-write"
										to="#"
										onClick={() => {
											setIsShowModal(true);
										}}
									>
										{translate("TR_VIEW")}</Link>
									<div className="clear"></div>
								</div>
								<div className="line"></div>
								<div className="small-artist-list">
									{
										artworkData.artist ?
											<Link className="item" to={`/artist/${artworkData.artist.id}`}>
												<div className="image">
													<div className="image-cont">
														<img src={artworkData.artist?.imagePath ? artworkData.artist?.imagePath : "images/our-images/no-image.png"} alt="image" />
													</div>
												</div>
												<div className="name">{MainService.getValueByLanguageId(artworkData.artist?.names, languageId)}</div>
												<div className="clear"></div>
											</Link>
											: null
									}
								</div>

								<div className="description-title" ref={descriptionRef}>{translate("TR_DESCRIPTION")}</div>
								<div className="line"></div>
								<div className="description">
									<p>
										{MainService.getValueByLanguageId(artworkData?.descriptions, languageId)}
									</p>
								</div>
							</div>
						</div>
						<div className="product-photo pull-right">
							{
								artworkData && artworkData.imagePaths && artworkData.imagePaths.length ?
									artworkData.imagePaths.map((item, index) => {
										return <Link key={index} className="fancybox" data-fancybox="images" to="#"
											onClick={() => {
												setIsOpen(true);
												setFilePath(item.filePath ? item.filePath : "images/our-images/no-image.png")
											}}
										>
											<img src={item.filePath ? item.filePath : "images/our-images/no-image.png"} alt="image" />
										</Link>
									})
									: null
							}
						</div>
						<div className="clear"></div>
					</div>
					: null
			}
			<div className="clear"></div>
			<div className="big-catalog big-catalog-autor" id="tab-1" style={{ backgroundColor: "#2D2D2D", position: "relative" }}>
				<div
					className="title"
					ref={elementRef}
				>
					{translate("TR_OTHER_WORKS_BY_THE_AUTHOR")}
				</div>
				<div className="work-list work-list-no-shadow">
					<div className="work-list-cont">
						{
							artworkData && artistWorks && artistWorks.length && artistWorks.filter(item => item.id !== +itemId) && artistWorks.filter(item => item.id !== +itemId).length ?
								artistWorks.filter(item => item.id !== +itemId).map((item, index) => {
									return <div key={index} className="work-item">
										<Link className="image" to={`/artwork/${item.id}`}>
											<div className="image-cont">
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} alt="image" />
											</div>
										</Link>
										<Link className="name" to={`/artwork/${item.id}`}>{MainService.getValueByLanguageId(item.names, languageId)}</Link>
										<div className="line"></div>
										<span>
											<p style={{ display: "flex" }}>
												{
													item.materials && item.materials.length ?
														item.materials.map((el, idx) => {
															return <span key={idx}>{translate(el)}{`${item.materials.length === idx + 1 ? "" : ","}`}</span>
														})
														: null

												}
											</p>
											<p>{item.size} {translate("TR_SM")}, {moment(item.productionDate).format("YYYY")} {translate("TR_YEAR")}</p>
										</span>
									</div>
								})
								: null
						}

						<div className="clear"></div>
						{
							pagination && pagination.totalPages > currentPage && !isLoading && artistWorks.length ?
								<div ref={targetRef} style={{ height: '10px', width: "100%" }} />
								: null
						}
						{
							isLoading ?
								<div style={{
									display: "flex",
									justifyContent: "center"
								}}>
									<span className="custom-loader"></span>
								</div>
								: null
						}
					</div>
				</div>

			</div>


		</div>

	)

}
