import React, { useEffect, useRef, useState } from "react";
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "../lang/TranslationProvider";
import moment from "moment/moment";
import parse from 'html-react-parser';

export default function Blogs() {

	const { categoryId } = useParams();
	const { translate } = useTranslation();
	const { language, languages } = useSelector(state => state.language);

	const [blogCategories, setBlogCategories] = useState([]);
	const [blogs, setBlogs] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [languageId, setLanguageId] = useState(null);
	const [pageContent, setPageContent] = useState(null);

	const targetRef = useRef(null);

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		getAllCategories();
		getPageContentByKeyName()
	}, [])

	const getPageContentByKeyName = () => {
		MainService.callApi({
			api: ApiService.getPageContentByKeyName,
			data: "KEY_BLOG",
			cb: response => {
				if (response && response.data) {
					setPageContent(response.data);
				}
			},
		});
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, [])

	useEffect(() => {
		getBlogs(1);
	}, [categoryId])

	useEffect(() => {
		const handleIntersect = (entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					getBlogs(currentPage + 1);
				}
			});
		};
		const observer = new IntersectionObserver(handleIntersect, {
			root: null,
			rootMargin: '0px',
			threshold: 1.0,
		});
		if (targetRef.current) {
			observer.observe(targetRef.current);
		}
		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current);
			}
		};
	}, [currentPage, pagination, isLoading]);

	const getAllCategories = () => {
		MainService.callApi({
			api: ApiService.getBlogCategories,
			data: {},
			cb: (response) => {
				if (response && response.data) {
					setBlogCategories(response.data);
				}
			},
		});
	}

	const getBlogs = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
		}
		if (categoryId) {
			data.categoryId = categoryId;
		}

		setIsLoading(true);
		MainService.callApi({
			api: ApiService.getBlogs,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagination) {
						setPagination(data.pagination)
					}
					if (data.pagedData && data.pagedData.length) {
						if (currentPage > 1) {
							setBlogs([...blogs, ...data.pagedData]);
						} else {
							setBlogs(data.pagedData);
						}
					} else {
						setBlogs([]);
					}
				}
				setIsLoading(false);
				setCurrentPage(currentPage)
			},
			_finally: () => {
				setIsLoading(false);
			},
		});
	}

	return (
		<div className="cont">
			{
				pageContent ?
					<div className="main-page-bunner exhibitions-page-bunner blogs-page-bunner">
						<div className="image pull-left">
							<div className="image-cont">
								{
									pageContent.imagePaths && pageContent.imagePaths.length ?
										<img src={pageContent.imagePaths[0]} alt="image" style={{ height: "100%" }} />
										: <img src="images/our-images/no-image.png" alt="no-image" style={{ height: "100%" }} />

								}
							</div>
						</div>
						<div className="text pull-right">
							<span className="label">{MainService.getValueByLanguageId(pageContent.titles, languageId)}</span>
							<div className="line"></div>
							<div className="name">{parse(MainService.getValueByLanguageId(pageContent.contents, languageId))}</div>
							<div className="tags">
								<div className="tags-cont">
									<Link className={!categoryId ? "active" : ""} to="/blogs">{translate("TR_ALL")}</Link>
									{
										blogCategories && blogCategories.length ?
											blogCategories.map((item, index) => {
												return <Link key={index} className={+categoryId === item.id ? "active" : ""} to={`/blogs/${item.id}`}>
													{MainService.getValueByLanguageId(item.names, languageId)}
												</Link>
											})
											: null
									}
									<div className="clear"></div>
								</div>
							</div>
							<div className="clear"></div>
						</div>
						<div className="clear"></div>
					</div>
					: null
			}
			<div className="exhibitions-list exhibitions-list-margin blogs-list-margin blogs-list-margin-no-shadow">
				<div className="exhibition-item-cont mobile">
					<div className="exhibition-item-cont-block" style={{ display: "flex", flexWrap: "wrap", gap: "20px", width: "100%" }}>
						{
							blogs && blogs.length ?
								blogs.map((item, index) => {
									return <div key={index} className="exhibition-item blog-item">
										<div className="date">
											<b>{moment(new Date(item.date)).format("DD.MM.YYYY")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link className="image-cont" to={`/blog/${item.id}`}>
												<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
											</Link>
										</div>
										<Link className="name" to={`/blog/${item.id}`}>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								})
								: null
						}
						<div className="clear"></div>
					</div>
				</div>
				<div className="column pull-left desktop">
					{
						blogs && blogs.length ?
							blogs.map((item, index) => {
								if (index % 2 === 0) {
									return <div key={index} className="exhibition-item blog-item">
										<div className="date">
											<b>{moment(new Date(item.date)).format("DD.MM.YYYY")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link className="image-cont" to={`/blog/${item.id}`}>
												<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
											</Link>
										</div>
										<Link className="name" to={`/blog/${item.id}`}>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								}
							})
							: null
					}
				</div>
				<div className="column pull-right column-margin-top desktop">
					{
						blogs && blogs.length ?
							blogs.map((item, index) => {
								if (index % 2 === 1) {
									return <div key={index} className="exhibition-item blog-item">
										<div className="date">
											<b>{moment(new Date(item.date)).format("DD.MM.YYYY")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link className="image-cont" to={`/blog/${item.id}`}>
												<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
											</Link>
										</div>
										<Link className="name" to={`/blog/${item.id}`}>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								}
							})
							: null
					}
				</div>
				<div className="clear"></div>
				{
					pagination && pagination.totalPages > currentPage && !isLoading && blogs.length ?
						<div ref={targetRef} style={{ height: '10px', width: "100%" }} />
						: null
				}
				{
					isLoading ?
						<div style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "80px",
							marginBottom: "80px",
						}}>
							<span className="custom-loader"></span>
						</div>
						: null
				}
			</div>

		</div>
	)

}
