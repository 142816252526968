import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { useSelector } from "react-redux";
import { useTranslation } from "../lang/TranslationProvider";
import { Link, useParams } from "react-router-dom";
import LightboxComponent from "../components/lightbox-component/LightboxComponent";
import parse from 'html-react-parser';

export default function Artists() {

	const { categoryId } = useParams();
	const targetRef = useRef(null);
	const { translate } = useTranslation();
	const { language, languages } = useSelector(state => state.language);
	const [artistWorksSwiperRef, setArtistWorksSwiperRef] = useState(null);
	const [pageContent, setPageContent] = useState(null);
	const [languageId, setLanguageId] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [artists, setArtists] = useState([]);
	const [artistCategories, setArtistCategories] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const [filePath, setFilePath] = useState("");

	useEffect(() => {
		getPageContentByKeyName();
		getAllCategories();
	}, [])

	useEffect(() => {
		getArtists(1);
	}, [categoryId])

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, [])

	useEffect(() => {
		const handleIntersect = (entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					getArtists(currentPage + 1);
				}
			});
		};
		const observer = new IntersectionObserver(handleIntersect, {
			root: null,
			rootMargin: '0px',
			threshold: 1.0,
		});
		if (targetRef.current) {
			observer.observe(targetRef.current);
		}
		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current);
			}
		};
	}, [currentPage, pagination, isLoading]);

	const getAllCategories = () => {
		MainService.callApi({
			api: ApiService.getArtistAllCategories,
			data: {},
			cb: (response) => {
				if (response && response.data) {
					setArtistCategories(response.data);
				}
			},
		});
	}

	const getPageContentByKeyName = () => {
		MainService.callApi({
			api: ApiService.getPageContentByKeyName,
			data: "KEY_ARTIST",
			cb: response => {
				if (response && response.data) {
					setPageContent(response.data);
				}
			},
		});
	}

	const getArtists = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
			artworksCount: 10,
		}
		if (categoryId) {
			data.categoryId = categoryId;
		}

		setIsLoading(true);
		MainService.callApi({
			api: ApiService.getArtists,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagination) {
						setPagination(data.pagination)
					}
					if (data.pagedData && data.pagedData.length) {
						if (currentPage > 1) {
							setArtists([...artists, ...data.pagedData]);
						} else {
							setArtists(data.pagedData);
						}
					} else {
						setArtists([]);
					}
				}
				setIsLoading(false);
				setCurrentPage(currentPage)
			},
			_finally: () => {
				setIsLoading(false);
			},
		});
	}

	return (
		<div className="cont">
			<LightboxComponent
				isOpen={isOpen}
				filePath={filePath}
				onHide={() => {
					setIsOpen(false);
					setFilePath("");
				}}
			/>
			{
				pageContent ?
					<div className="main-page-bunner main-page-bunner-artists">
						<div className="image pull-left desktop">
							<div className="image-cont">
								{
									pageContent.imagePaths && pageContent.imagePaths.length ?
										<img src={pageContent.imagePaths[0]} alt="image" />
										: <img src="images/our-images/no-image.png" alt="no-image" />
								}
							</div>
						</div>
						<div className="text pull-right">
							<span className="label">{MainService.getValueByLanguageId(pageContent.titles, languageId)}</span>
							<div className="line"></div>
							<div className="name">{parse(MainService.getValueByLanguageId(pageContent.contents, languageId))}</div>
							<div className="tags">
								<div className="tags-cont">
									<Link className={!categoryId ? "active" : ""} to="/artists">{translate("TR_ALL")}</Link>
									{
										artistCategories && artistCategories.length ?
											artistCategories.map((item, index) => {
												return <Link key={index} className={+categoryId === item.id ? "active" : ""} to={`/artists/${item.id}`}>
													{MainService.getValueByLanguageId(item.names, languageId)}
												</Link>
											})
											: null
									}
									<div className="clear"></div>
								</div>
							</div>
						</div>
						<div className="clear"></div>
					</div>
					: null
			}
			<div className="big-artist-list" style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
				{
					artists && artists.length ?
						artists.map((item, index) => {
							return <div key={index} className={`big-artist-item `}>
								<Link className="image" to={`/artist/${item.id}`}>
									<div className="image-cont">
										<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} alt="image" style={{ height: "100%" }} />
									</div>
								</Link>
								<div className="text">
									<Link className="name" to={`/artist/${item.id}`}>
										{MainService.getValueByLanguageId(item.names, languageId)}
									</Link>
									<p>
										{MainService.getValueByLanguageId(item.shortBios, languageId)}
									</p>
								</div>
								<div className="clear"></div>
								{
									item.artworks && item.artworks.length ?
										<div className="work-list" count="7">
											<div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slidePrev()}></div>
											<div className="list">
												<div className="list-cont">
													<Swiper
														slidesPerView={1}
														spaceBetween={10}
														loop
														onSwiper={(ref) => setArtistWorksSwiperRef(ref)}
														updateOnWindowResize
														breakpoints={{
															1024: {
																slidesPerView: 7,
																spaceBetween: 0,
															},
														}}
														className="mySwiper"
													>
														{
															item.artworks.map((el, idx) => {
																return <SwiperSlide key={idx}>
																	<Link
																		className="work-image fancybox"
																		to="#"
																		onClick={() => {
																			setIsOpen(true);
																			setFilePath(el.imagePath ? el.imagePath : "images/our-images/no-image.png")
																		}}
																	>
																		<img src={el.imagePath ? el.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} alt="image" />
																	</Link>
																</SwiperSlide>
															})
														}

													</Swiper>
													<div className="clear"></div>
												</div>
											</div>
											<div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slideNext()}></div>
											<div className="clear"></div>
										</div>
										: null
								}
								<div className="next d-block"></div>
								<div className="line"></div>
								<div className="count">{item.artworksCount} {translate("TR_WORK")}</div>
								<Link className="view" to={`/artist/${item.id}`}>{translate("TR_VIEW")}</Link>
								<div className="clear"></div>
							</div>
						})
						: null
				}
			</div>
			<div className="clear"></div>
			{
				pagination && pagination.totalPages > currentPage && !isLoading && artists.length ?
					<div ref={targetRef} style={{ height: '10px', width: "100%" }} />
					: null
			}
			{
				isLoading ?
					<div style={{
						display: "flex",
						justifyContent: "center"
					}}>
						<span className="custom-loader"></span>
					</div>
					: null
			}

		</div>
	);
}
